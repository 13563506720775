<template>
  <div class="project-sales-reservation-info p-2">
    <h3 class="mb-4">Reservation Form</h3>

    <div v-if="projectPurchase !== null && reservation !== null">
      <div class="row">
        <!-- Purchaser 1 -->
        <div class="col-12 sm-col-6 sm-mxn-1">
          <div class="sm-mx-1 my-1">
            <h5 class="mb-3">Purchaser 1</h5>
            <display-data
              label="Name"
              :content="reservation.purchaserName1"
            ></display-data>
            <display-data
              label="IC/Passport"
              :content="reservation.purchaserIdentityCardOrPassport1"
            ></display-data>
            <display-data
              label="Gender"
              :content="reservation.gender1"
            ></display-data>
            <display-data
              label="Nationality"
              :content="reservation.nationality1"
            ></display-data>
            <display-data label="Signature">
              <img
                class="signature"
                :src="reservation.signedByPurchaser1"
                alt="Purchaser 1 Sign"
              />
            </display-data>
          </div>
        </div>

        <!-- Purchaser 2 -->
        <div
          class="col-12 sm-col-6"
          v-if="!$isStringEmpty(reservation.purchaserName2)"
        >
          <div class="sm-mx-1 my-1">
            <h5 class="mb-3">Purchaser 2</h5>
            <display-data
              label="Name"
              :content="reservation.purchaserName2"
            ></display-data>
            <display-data
              label="IC/Passport"
              :content="reservation.purchaserIdentityCardOrPassport2"
            ></display-data>
            <display-data
              label="Gender"
              :content="reservation.gender2"
            ></display-data>
            <display-data
              label="Nationality"
              :content="reservation.nationality2"
            ></display-data>
            <display-data label="Signature">
              <img
                class="signature"
                :src="reservation.signedByPurchaser2"
                alt="Purchaser 2 Sign"
              />
            </display-data>
          </div>
        </div>
      </div>

      <!-- Reservation Data -->
      <div class="row">
        <div class="col-12 sm-col-6 p-1">
          <display-data
            label="Bumiputera Status"
            :content="reservation.nonBumiOrForeigner"
          ></display-data>
          <display-data
            label="Mailing Address"
            :content="reservation.mailingAddress"
          ></display-data>
          <display-data
            label="Email"
            :content="reservation.email"
          ></display-data>

          <display-data
            label="Contact (H/P)"
            :content="reservation.phoneContact"
          ></display-data>
          <display-data
            v-if="!$isStringEmpty(reservation.officeContact)"
            label="Contact (Office)"
            :content="reservation.officeContact"
          ></display-data>
          <display-data
            v-if="!$isStringEmpty(reservation.faxContact)"
            label="Fax"
            :content="reservation.faxContact"
          ></display-data>
        </div>

        <!-- Unit Data -->
        <div class="col-12 sm-col-6 p-1">
          <display-data
            label="Project Phase"
            :content="reservation.projectPhase"
          ></display-data>
          <display-data
            label="Property Type"
            :content="reservation.propertyType"
          ></display-data>
          <display-data
            label="Parcel No."
            :content="reservation.unitPlot"
          ></display-data>
          <display-data
            label="Land Area"
            :content="`${parseInt(reservation.landArea)} ${
              projectPurchase.projectUnitFloorPlan.areaUnitType
            }`"
          ></display-data>
          <display-data
            label="Build Up Area"
            :content="`${parseInt(reservation.buildUpArea)} ${
              projectPurchase.projectUnitFloorPlan.areaUnitType
            }`"
          ></display-data>
          <display-data
            label="Sale Price"
            :content="`RM ${numberWithCommas(
              parseInt(reservation.salesPurchaseAgreementPrice)
            )}`"
          ></display-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from "@/utils/string";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {
    projectPurchase: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      numberWithCommas
    };
  },
  computed: {
    reservation() {
      return this.projectPurchase.projectPurchaseReservation ?? null;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-sales-reservation-info {
  .signature {
    width: 100px;
    max-width: 100%;
    height: 100px;
  }
}
</style>
